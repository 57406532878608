import React from "react"
import { Link } from "gatsby"

import styled, { createGlobalStyle } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import Img from "gatsby-image"

import Layout from "./layout"

export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    color: #222;
    background-color: #fff;
  }

  a:link {
    color: #1a0dab;
  }
  a:hover {
    color: #1a0dab;
  }
  a:visited {
    color: #1a0dab;
  }
`

export const Container = styled.div`
  font-size: 14pt;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium",
    "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  margin: 0 auto;
  width: 95%;
  max-width: 860px;
  padding: 0 1.0875rem 1.45rem;

  @media (max-width: 768px) {
    font-size: 12pt;
    width: 94%;
  }
`

export const SectionTitle = styled.h2`
  margin: 55px auto 30px auto;
  text-align: center;
  padding: 0;
  font-size: 44pt;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 25pt;
  }
`

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "cllctv.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ImfImage: file(relativePath: { eq: "fes_info.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ogpImage: file(relativePath: { eq: "imc_ogp.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const CllctvImage = () => {
  const data = useStaticQuery(query)
  return (
    <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="cllctv" />
  )
}

const IndexPage = () => {
  const url = "https://internalmeeting.com/fes/"
  const imageUrl = `https://i.gyazo.com/13c5e6224440597717815d35110799ff.png`
  const ogDescription = `鶴舞DAYTRIPとK.DハポンでInternal Meeting Festivalを開催します`

  const data = useStaticQuery(query)

  return (
    <Layout>
      <Global />
      <Container>
        <SEO
          title="Internal Meeting Festival"
          url={url}
          imageUrl={imageUrl}
          description={ogDescription}
        />
        <div style={{ textAlign: "center" }}>
          {/*<h1>Internal Meeting Festival 2022</h1>*/}

          <div style={{ margin: "3rem auto", maxWidth: "500px" }}>
            <Img
              style={{ margin: "0 auto" }}
              imgStyle={{
                objectPosition: "center center",
                objectFit: "contain",
              }}
              fluid={data.ImfImage.childImageSharp.fluid}
              alt="Internal Meeting Festival / 2022年5月3日と5月4日に鶴舞DaytripとKDハポンで開催。"
            />
            <a href='https://i.gyazo.com/dfce3e92ccbd17c38a5a8cea3c0e2c1a.jpg' target={'_blank'}>【タイムテーブル】</a>
          </div>

          <ul style={{ listStyle: "none" }}>
            <li>
              <a href="../compi">Internal Meeting Compilation</a>
            </li>
            <li>
              <a
                href="https://forms.gle/iKu75UcwRKKB5zLR8"
                target="_blank"
                rel="noopener noreferrer"
              >
                予約フォーム
              </a>
            </li>
          </ul>
        </div>

        <footer
          style={{
            marginTop: 90,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 20,
            textAlign: "center",
          }}
        >
          © 2022
          {` `}
          <a
            href="http://cllctv-jp.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            cllctv.
          </a>
        </footer>
      </Container>
    </Layout>
  )
}

export default IndexPage
